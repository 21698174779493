<template>
  <div class="page bg-white">
    <van-panel >

      <div slot="footer" class="footer">
        <p class="title2 mb10">{{title}}</p>
        <!-- <p class="text-base font-14 mb10">线上活动</p> -->
        <van-image width="100" height="100" :src="qr" class="mt10"/>
        <p class="text-base font-16 mt10">微信扫一扫，即可获得电子票</p>
      </div>
    </van-panel>
  </div>
</template>
<script>
import { Row, Col, Panel } from "vant";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Panel.name]: Panel
  },
  data() {
    return {
        qr:'',
        title:''
    };
  },
  mounted: function() {
      this.qr = this.$route.query.qr;
      this.title = this.$route.query.title;
  },
  methods: {}
};
</script>
<style lang="less">
  .page{
      height: 100vh;
  }
  .footer{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      
  }
</style>
